/* @import '~react-image-gallery/styles/css/image-gallery.css'; */

html,
body {
  height: (100%);
  overflow: auto;
  box-sizing: border-box;
  background-color: hsl(0, 0%, 96%);
}
.box-shadow-y {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
.no-shadow {
  box-shadow: none;
}
div.vertical-align {
  display: flex;
  align-items: center;
  min-width: 35em;
  min-height: 38em;
  justify-content: center;
}
.px-1,
.p-1 {
  padding-right: 0.2rem;
  padding-left: 0.2rem;
}
.py-1,
.p-1 {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.margin-10-25 {
  margin-top: -10px;
  margin-bottom: -25px;
}
.margin-9 {
  margin-top: -9px;
}
.menu-container {
  position: fixed;
  top: 52px;
  margin: auto;
  min-width: 300px;
  z-index: 10;
  background-color: transparent;
  height: 100%;
  overflow-y: auto;
  transform: translateX(-100%);
  transition: all ease 0.5s;
}

.toggler {
  margin-left: 0;
  cursor: pointer;
}

.menu-container.active {
  transform: translate(0%);
}

#burger {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
  width: 56px;
  cursor: pointer;
}

#burger span {
  display: block;
  width: 40%;
  border-radius: 10px;
  height: 2px;
  background: #4a4a4a;
  transition: all 0.3s;
  position: relative;
}

#burger span:nth-child(1) {
  margin-top: 18px;
  margin-bottom: -22px;
}

span + span {
  margin-top: 1px;
}

.active > span:nth-child(1) {
  animation: ease 0.7s top forwards;
}

.not-active > span:nth-child(1) {
  animation: ease 0.7s top-2 forwards;
}

.active > span:nth-child(2) {
  animation: ease 0.7s bottom forwards;
}

.not-active > span:nth-child(2) {
  animation: ease 0.7s bottom-2 forwards;
}

.active > span:nth-child(3) {
  animation: ease 0.7s scaled forwards;
}

.not-active > span:nth-child(3) {
  animation: ease 0.7s scaled-2 forwards;
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 6px;
    transform: rotate(0);
  }
  100% {
    top: 6px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 6px;
    transform: rotate(45deg);
  }
  50% {
    top: 6px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: -5px;
    transform: rotate(0);
  }
  50% {
    bottom: 22px;
    transform: rotate(0);
  }
  100% {
    bottom: 22px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 22px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 22px;
    transform: rotate(0);
  }
  100% {
    bottom: -5px;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
/* Custom Fadein Animation */
#fadein,
.fadein {
  animation: fadeInAnimation ease 0.7s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
#fadein2 {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.scale-up-top {
  -webkit-animation: scale-up-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* custom Calender Today */
.dayText {
  padding: 0px !important;
}
div.menu-list > div.profile {
  padding: 5px;
}
/* Start Custom-Profile-Sidebar */
.menu > div.profile-sidebar {
  padding: 10px;
  margin-bottom: 15px;
}

div > div.profile-icon {
  display: flex;
  justify-content: center;
}

div > div.profile-icon span {
  font-size: 0.8em;
  cursor: pointer;
}

div > div.profile-icon p.control > button {
  border-color: white;
}

div > div.profile-icon span.shortcut-grey:hover {
  color: grey;
}

ul.menu-list li {
  margin-top: 2px;
  margin-bottom: 2px;
}

span.shortcut-red:hover {
  color: rgb(224, 83, 58);
}
/* End Custom-Profile-Sidebar */

/* Pop Up Modal Custom Width */
@media screen and (min-width: 769px) {
  .modal-card,
  .modal-content {
    width: 756px;
  }
}
@media screen and (min-width: 992px) {
  .modal-card,
  .modal-content {
    width: 980px;
  }
}

/* start custom menu-modal */
.c-box-menu-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: #3f8ed0;
}
.c-title-menu-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-active-menu {
  background-color: #00a78e;
  border-radius: 4px;
}
.c-menu-detail {
  flex-grow: 4;
  background-color: #3f8ed0;
  width: 100%;
}
.c-box-menu-detail {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.c-menu-item {
  display: flex;
  width: 100px;
  height: 80px;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.c-close-menu-modal {
  position: absolute;
  flex-grow: 1;
  display: flex;
  bottom: 20px;
  justify-content: center;
  align-items: center;
}
.c-height-25 {
  height: 25px;
}
/* end custom menu-modal */

li > div {
  padding: 0.5em 0.75em;
  cursor: pointer;
}

li > div:hover {
  background-color: hsl(0, 0%, 96%);
}

.navbar-item {
  font-size: 0.8rem;
}

.navbar-item > i {
  font-size: 1rem;
}

/* custom profile add picture */
#profile-icon {
  position: absolute;
  right: 52px;
  top: 52px;
}

#profile-pic:hover {
  background-color: rgb(233, 233, 233);
  cursor: pointer;
}

/* custom-badge-notification-&-tag-post */
a.navbar-item > span.badge-notification,
span.badge-tag,
span.custom-badge {
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: #3273dc;
  padding: 0px 4px 0px 4px;
  color: white;
  border-radius: 4px;
  text-align: right;
}
/* Content Wrap */
.content-wrap {
  display: -webkit-flex; /* Safari */
  -webkit-flex-wrap: wrap; /* Safari 6.1+ */
  display: flex;
  flex-wrap: wrap;
}
/* group-button-disabled */
.control .is-disabled {
  cursor: context-menu;
}
/* Table th Custom Font */
table th,
table td {
  font-size: 0.9em;
}
/* custom table content vertical align middle / center */
.middle {
  vertical-align: middle !important;
}

/* Custom Checkbox */
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-top: 14px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* ignore click / avoid click */
.avoid-click {
  pointer-events: none;
}

/* Custom Handler Scrollbar */
/* scrollbar none */
/* ::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  -box-shadow: none;
}
::-webkit-scrollbar-thumb {
  background: white;
  box-shadow: none;
} */
/* ::-webkit-scrollbar-thumb:window-inactive {
  background: none;
} */
/* scrollbar none end */

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
}
/* End Custom Handler Scrollbar */

#content-page {
  overflow: auto !important;
}

/* Custom Checkbox - On mouse-over, add grey background */
.custom-checkbox:hover input ~ .checkmark {
  background-color: #e7e7e7;
}

/* Custom Checkbox - When checkbox is checked, add blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #3f8ed0;
}

/* Custom Checkbox - checkmark hidden when not checked */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Custom Checkbox - Show checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Custom Checkbox - Style checkmark */
.checkmark:after {
  left: 10px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Custom Input */
div.custom-input {
  font-style: italic;
  width: 100%;
  padding: 6px 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 1px rgba(255, 56, 96, 0.25) inset;
}

/* Custom disable select */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Custom ignore click / avoid click */
.avoid-click {
  pointer-events: none;
}

.view {
  margin-top: 7px;
  padding-bottom: 4px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(128, 128, 128, 0.353);
  padding-left: 8px;
}

/* Custom ToolTip */
span.react-tooltip-lite {
  background-color: rgba(17, 16, 16, 0.952);
  color: white;
  border-radius: 3px;
  box-sizing: border-box;
}

.react-tooltip-lite-arrow {
  border-color: rgba(17, 16, 16, 0.952);
}

/* custom button cancel/back */
.custom-grey:hover,
a.custom-grey:hover {
  background-color: #ebebeb;
}
button.custom-borderless {
  border: none;
}

/* Datepicker Popup */
div.react-datepicker-popper {
  z-index: 999;
}

/* Set Cursor look*/
.cursor-text {
  cursor: text !important;
  color: #272626 !important;
  border-color: #dbdbdb !important;
  background-color: white !important;
}
/* Custom hover hand pointer */
div.hand-pointer:hover {
  cursor: pointer;
  background-color: rgb(245, 245, 245);
}

.hand-pointer2:hover {
  cursor: pointer;
}

.hand-pointer:hover {
  cursor: pointer;
}

.border:hover {
  border-radius: 10px;
}

/* Custom NavBell & NavUser */
.custom-hover-light-primary:hover {
  background-color: #5cd1c0 !important;
}
.custom-hover-light-success:hover {
  background-color: #7ac795 !important;
}
.custom-hover-light-link:hover {
  background-color: #6290da !important;
}
.custom-hover-light-info:hover {
  background-color: #598ada !important;
  color: white !important;
}
.custom-hover-light-warning:hover {
  background-color: #ffec9f !important;
}
.custom-hover-light-danger:hover {
  background-color: #eb6e87 !important;
}
.custom-hover-light-black:hover {
  background-color: #272626 !important;
}
.custom-hover-light-dark:hover {
  background-color: #4a4a4a !important;
}

/* Custom Profile Picture */
.custom-profile {
  height: auto;
  margin-top: -65px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 100px;
  border: 5px solid white;
}

/* Custom Dropzone Input */
.dropzone {
  border-radius: 5px;
  padding-top: 1px;
  text-align: center;
  min-height: 130px;
  cursor: pointer;
}

/* Custom Dropzone Profile Input */
.dropzone-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: dashed 3px #eee;
  border-radius: 5px;
  text-align: center;
  min-width: 134px;
  min-height: 134px;
  cursor: pointer;
}

.dropzone--isActive,
.dropzone-profile--isActive {
  border: dashed 3px green;
}

.no--dropzone {
  cursor: pointer;
}

/* Custom multiselect option */
li.rw-multiselect-tag div,
li.rw-multiselect-tag span {
  padding: 0px 0px;
}

button.rw-btn-select > span {
  padding-right: 0.3em;
}

/* Custom ActiveTab */
.tabs li.is-active > div {
  border-bottom: 1px solid #3273dc;
  color: #3273dc;
}

.tabs li > div:hover {
  border-bottom: 1px solid #363636;
  background-color: white;
  color: #363636;
}

.tabs li.is-active > div:hover {
  border-bottom: 1px solid #3273dc;
  background-color: white;
  color: #3273dc;
}

/* Custom Text-Overflow */
.custom-text-overflow {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #363636;
}

/* Custom Center Content */
.custom-flex-start {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.custom-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin: 10px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #ddd;
}

/* Custom Card Flex */
.custom-card-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
}

.custom-content-flex {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;
  font-size: 13px;
}

.custom-box-flex {
  color: white;
  flex-grow: 1;
  padding: 5px 0px;
  border: 1px solid white;
}

.custom-weekday {
  background-color: #6cc2ff;
}

.custom-weekend {
  background-color: #da7852;
}
.custom-weekday-active,
.custom-weekday:hover {
  background-color: #0095ff;
  color: white;
}
.custom-weekend-active,
.custom-weekend:hover {
  background-color: #dd470c;
}

.custom-weekday:hover,
.custom-weekend:hover {
  cursor: pointer;
}

.custom-schedule {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-schedule-item {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 6px;
  margin-bottom: 6px;
  background-color: #0095ff;
}

.custom-schedule-column {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  margin-bottom: 6px;
}

.card-content {
  padding: 1.5rem 0.3rem 1.5rem 0.3rem;
}

.overflow-auto {
  overflow-x: auto;
}

.text-wrap {
  word-wrap: break-word;
}

.custom-text-14 {
  font-size: 14px;
}
/* Custom fade-out */
.custom-fade-out {
  animation: fadeOut ease 10s;
  -webkit-animation: fadeOut ease 10s;
  -moz-animation: fadeOut ease 10s;
  -o-animation: fadeOut ease 106s;
  -ms-animation: fadeOut ease 10s;
}
/* Custom bubble messages */
.bubble::before {
  content: ' ';
  height: 0;
  position: absolute;
  top: 8px;
  width: 0;
  z-index: 1;
  left: -10px;
  border: medium solid #ebfffc;
  border-width: 10px 10px 10px 0;
  border-color: transparent #ebfffc transparent transparent;
}
.bubblea::before {
  content: ' ';
  height: 0;
  position: absolute;
  top: 8px;
  width: 0;
  z-index: 1;
  left: -10px;
  border: medium solid #ebfffc;
  border-width: 10px 10px 10px 0;
  border-color: transparent #ebfffc transparent transparent;
}
.bubblen::before {
  content: ' ';
  height: 0;
  position: absolute;
  top: 8px;
  width: 0;
  z-index: 1;
  left: -10px;
  border: medium solid #ededed;
  border-width: 10px 10px 10px 0;
  border-color: transparent #ededed transparent transparent;
}
#dashboard-bubble::before {
  content: ' ';
  height: 0;
  position: absolute;
  top: 54px;
  width: 0;
  z-index: 1;
  left: 2px;
  border: medium solid #ebfffc;
  border-width: 10px 10px 10px 0;
  border-color: transparent #ebfffc transparent transparent;
}
/* Custom timeline */
.timeline {
  overflow: hidden;
}
.timeline ul {
  background: #456990;
  padding: 15px 0;
}

.timeline ul li {
  list-style-type: none;
  position: relative;
  width: 6px;
  margin: 0 auto;
  padding-top: 50px;
  background: #fff;
}

.timeline ul li::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: inherit;
  z-index: 1;
}

.timeline ul li div {
  position: relative;
  bottom: 0;
  width: 400px;
  padding: 15px;
  background: #f45b69;
}

.timeline ul li div::before {
  content: '';
  position: absolute;
  bottom: 7px;
  width: 0;
  height: 0;
  border-style: solid;
}

.timeline ul li:nth-child(odd) div {
  left: 45px;
}

.timeline ul li:nth-child(odd) div::before {
  left: -15px;
  border-width: 8px 16px 8px 0;
  border-color: transparent #f45b69 transparent transparent;
}

.timeline ul li:nth-child(even) div {
  left: -439px;
}

.timeline ul li:nth-child(even) div::before {
  right: -15px;
  border-width: 8px 0 8px 16px;
  border-color: transparent transparent transparent #f45b69;
}

time {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 8px;
}
/* Custom Dropdown Up Menu */
#bottomNavbar {
  box-shadow: 0 4px 6px 4px rgb(160, 160, 160);
}
.customdropdown {
  position: relative;
}
.customdropdownmenu {
  position: absolute;
  display: block;
  bottom: 3.6rem;
  right: 0%;
  width: 16.8rem;
  height: fit-content;
  background-color: #00a78e;
  color: rgb(39, 39, 39);
  border-radius: 0.25rem;
  box-shadow: 0 4px 6px -4px black;
  opacity: 1;
  transform: translateY(10px);
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
}
.customdropdownmenu2 {
  position: absolute;
  display: block;
  bottom: 4.1rem;
  right: 0%;
  min-width: fit-content;
  height: fit-content;
  background-color: #00a78e;
  color: rgb(39, 39, 39);
  border-radius: 0.25rem;
  box-shadow: 0 4px 6px -4px black;
  opacity: 1;
  transform: translateY(10px);
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
}
.customdropdowncontent {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  margin: 0.2rem;
  padding: 0;
}
.customdropdowncontent2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.2rem;
}
.customdropdowncontentlist {
  display: flex;
  width: 8rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.1rem;
  padding: 0.2rem;
  font-size: 0.8rem;
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0.2rem;
}
.no-pointer {
  cursor: default;
}
.contentlistactive {
  background-color: rgba(255, 255, 255, 0);
}
.icon-size {
  font-size: 1rem;
}
/* Custom Color */
.custom-light-grey {
  background-color: hsl(0, 0%, 93%);
}
/* Material Design Icon */
.icon-7 {
  width: 0.8rem;
}
.icon-6 {
  width: 2.2rem;
}

/* Flexbox */

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

/* index search advance */
.search-adv {
  width: 514px;
}

/* React Quill Rich Text Editor */
.ql-editor {
  max-height: 680px !important;
  overflow: auto;
  font-size: 14px;
}
.ql-tooltip {
  left: 10px !important;
}
/* Sticky Quill Toolbar */
.ql-toolbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
}

.ql-clipboard {
  position: fixed !important;
}
/* Custom ReadMore */
.read-more {
  color: rgb(192, 192, 192);
  cursor: pointer;
}

/* sticky element */
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

/* number/text alignment */
input[type='number'] {
  text-align: right;
}

input[type='text'] {
  text-align: left;
}

/* GENERAL MEDIA QUERIES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@media screen and (max-width: 1110px) {
  .timeline ul li div {
    width: 250px;
  }
  .timeline ul li:nth-child(even) div {
    left: -289px;
    /*250+45-6*/
  }
}

@media screen and (max-width: 768px) {
  body {
    height: 100% !important;
  }
  .column {
    padding: 0.75rem 0.5rem 2.2rem 0.5rem !important;
  }
  .search-adv {
    width: 280px;
  }
}

@media screen and (max-width: 600px) {
  .timeline ul li {
    margin-left: 20px;
  }
  .timeline ul li div {
    width: calc(100vw - 91px);
  }
  .timeline ul li:nth-child(even) div {
    left: 45px;
  }
  .timeline ul li:nth-child(even) div::before {
    left: -15px;
    border-width: 8px 16px 8px 0;
    border-color: transparent #f45b69 transparent transparent;
  }
}

/* EXTRA/CLIP PATH STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.timeline-clippy ul li::after {
  width: 40px;
  height: 40px;
  border-radius: 0;
}

.timeline-rhombus ul li::after {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.timeline-rhombus ul li div::before {
  bottom: 12px;
}

.timeline-star ul li::after {
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}

.timeline-heptagon ul li::after {
  clip-path: polygon(
    50% 0%,
    90% 20%,
    100% 60%,
    75% 100%,
    25% 100%,
    0% 60%,
    10% 20%
  );
}

.timeline-infinite ul li::after {
  animation: scaleAnimation 2s infinite;
}

@keyframes scaleAnimation {
  0% {
    transform: translateX(-50%) scale(1);
  }
  50% {
    transform: translateX(-50%) scale(1.25);
  }
  100% {
    transform: translateX(-50%) scale(1);
  }
}
/* End Custom Timeline */

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

/* custom table report */
#rapor-style > table:nth-child(1) *,
#rapor-style > table:nth-child(18) * {
  border: none;
}

#rapor-style .boxr {
  border: 1px solid #dbdbdb !important;
}

#rapor-style .is-bordered {
  min-height: 50px;
}

#rapor-style > table:nth-child(4) > tbody > tr:nth-child(4) > th:nth-child(3) {
  text-align: center;
}

#rapor-style > table:nth-child(4) > tbody > tr:nth-child(4) > td {
  font-weight: bold;
}

#rapor-style > table:nth-child(4) > tbody > tr:nth-child(4) {
  padding-top: 20px;
}

#rapor-style > table:nth-child(21) * {
  border: none;
}

#rapor-style > table:nth-child(23) * {
  border: none;
}

/* Navbar > submenu */
/* Custom Font */
.is-size-12 {
  font-size: 0.9rem;
}
/* border submenu none */
.menu-list li ul {
  border-left: none !important;
}

/* Custom table Account Statement */
#accounts * {
  border: none;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}

@media (min-width: 769px) {
  .menu-container {
    width: 25%;
    min-width: auto;
    transform: translateX(0%);
  }
  .toggler,
  .nav-toggler {
    display: none;
  }
  .field-body > .field:not(.is-narrow) {
    box-flex: 1;
    flex-grow: 1;
  }
  .field-body > .field {
    flex: 1;
  }
}

@media (min-width: 1024px) {
  .menu-container {
    width: 16.6667%;
    min-width: auto;
    transform: translateX(0%);
  }

  /* quil editor style image */
  #quilEditor img {
    max-width: 600px !important;
    height: auto;
  }
}

/* quil editor height */
.ql-editor {
  height: 250px !important;
  max-height: 250px !important;
  overflow: auto;
}

/* Custom Sidemenu */
#sm {
  background-color: #263544 !important;
}

/* Sidemenu Active */
.menu-list a,
#logoutBtn {
  color: #dadada;
}
.menu-list a:hover,
#logoutBtn:hover {
  background-color: #1f2530 !important;
  color: rgb(213, 213, 213);
}
.menu-list a.is-active {
  background-color: #10152c !important;
  color: rgb(213, 213, 213);
}

/* Menu SubMenu */
div.has-submenu {
  color: rgb(213, 213, 213) !important;
}
div.has-submenu:hover {
  background-color: #1d2530 !important;
  color: rgb(213, 213, 213);
}

.custom-background-active {
  background-color: #10152c;
  color: rgb(213, 213, 213);
}

.menu-top {
  color: #263544 !important;
  padding: 0px !important;
}

.menu-top:hover {
  background-color: #ffffff !important;
  color: #3e8ed0 !important;
}

#Navigation {
  background-color: #263544;
  color: #fff;
  cursor: text;
  font-size: 13px !important;
}


/* react awesome calendar custom */
.currentDay {
  background-color: #85c4ff !important;
}
button.modeButton:first-of-type {
  display: none !important;
}
.dailyEventWrapper {
  cursor: pointer;
}

/* @import 'react-widgets/dist/css/react-widgets.css'; */
